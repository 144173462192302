import React, { useRef, useState } from 'react';

import router, { useRouter } from 'next/router';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteApiV2BlogAutocomplete, laravelRouteBlogSearch } from '@static_components/laravelLinks';
import { useCombobox } from 'downshift';

import styles from './SearchBar.module.css';
import { ReactComponent as SVGSearch } from './search.svg';

let autocompleteTimeout: ReturnType<typeof setTimeout>;

export default function SearchBar() {
    const { t } = useTranslation('pages/blog/navigation');
    const [items, setItems] = useState([]);
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const formEl = useRef<HTMLFormElement>(null);
    const { locale } = useRouter();
    const [searchValue, setSearchValue] = useState('');
    const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectedItem } = useCombobox({
        async onInputValueChange({ inputValue }) {
            if (inputValue) {
                setSearchValue(inputValue ? inputValue : '');
            }
            if (autocompleteTimeout) {
                clearTimeout(autocompleteTimeout);
            }
            if (!inputValue) {
                setItems([]);
                return;
            }
            autocompleteTimeout = setTimeout(async () => {
                const searchParams = new URLSearchParams();
                searchParams.set('query', inputValue);
                const response = await fetch(`${laravelRouteApiV2BlogAutocomplete(locale)}?${searchParams.toString()}`);
                const items = await response.json();

                setItems(items);
            }, 200);
        },
        id: 'blog-nav-search',
        items,
        onSelectedItemChange: (e) => {
            if (formEl.current) {
                router.push({
                    pathname: '/blog/search',
                    query: { query: e.selectedItem },
                });
            }
        },
    });

    const handleInputFocus = () => {
        setInputFocused(true);
    };
    const handleInputBlur = () => {
        setInputFocused(false);
    };

    return (
        <form
            className={styles.form}
            ref={formEl}
        >
            <fieldset className={styles.fieldset}>
                <input
                    className={styles.input}
                    type="search"
                    name="query"
                    data-test="query"
                    aria-label={t('pages/blog/search/index:searchFormResultsLabelShort')}
                    placeholder={t('pages/blog/search/index:searchFormResultsLabelShort')}
                    {...getInputProps({
                        onFocus: handleInputFocus,
                        onBlur: handleInputBlur,
                        onKeyUp: (e) => {
                            if (e.key === 'Enter') {
                                router.push({
                                    pathname: '/blog/search',
                                    query: { query: searchValue },
                                });
                            }
                        },
                    })}
                />
                <button
                    className={styles.button}
                    aria-label={t('pages/blog/search/index:searchFormResultsLabelShort')}
                    onClick={(event) => {
                        event.preventDefault();
                        router.push({
                            pathname: '/blog/search',
                            query: { query: searchValue },
                        });
                    }}
                >
                    <SVGSearch
                        className={styles.searchIcon}
                        width="16"
                        height="16"
                    />
                </button>
            </fieldset>
            <ul
                className={styles.autocompleteDropdown}
                hidden={!isOpen || !items.length}
                {...getMenuProps()}
            >
                {isOpen &&
                    items.map((item, index) => (
                        <li
                            key={item}
                            className={[styles.autocompleteItem, highlightedIndex === index ? styles.autocompleteItemHighlighted : null, selectedItem === item ? styles.autocompleteItemSelected : null].filter(Boolean).join(' ')}
                            {...getItemProps({ item, index })}
                        >
                            <span>{item}</span>
                        </li>
                    ))}
            </ul>
        </form>
    );
}
